<template>
  <div class="NewsList">
    <!-- <Reserve :open="open" /> -->
    <div class="banner">
      <Head />
    </div>
    <div class="conent">
      <List />
    </div>
    <Floor />
    <!-- <ReserveModels ref="ReserveModels" /> -->
  </div>
</template>
<script>
import Reserve from '@/components/Reserve'
import Head from '@/components/Head'
import Floor from '@/components/Floor'
import ReserveModels from '@/components/ReserveModels'
import List from './models/List'
import { mapMutations } from 'vuex'
export default {
  name: 'NewsList',
  components: { Reserve, Head, Floor, List, ReserveModels },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  methods: {
    ...mapMutations(['ADD_keepAliveKey', 'DEL_ADD_keepAliveKey']),
    open() {
      this.$refs['ReserveModels'].showGame = true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/NewsDetails') {
      this.ADD_keepAliveKey('NewsList')
    } else {
      this.DEL_ADD_keepAliveKey('NewsList')
    }
    next()
  }

}
</script>
<style lang="scss" scoped>
.NewsList {
  min-height: 100vh;
  .banner {
    height: 375px;
    width: 100%;
    background: url('https://thcdn.gggamedownload.com/source/assets/neiye/sucai-14.jpg') no-repeat center;
  }
}
</style>
