<template>
  <div class="body">
    <div class="body">
      <div class="header">
        <ul>
          <li
            v-for="item in titleList"
            :key="item.id"
            :class="{ active: item.id === activeBorder }"
            @click="handleChangeType(item)"
          >
            {{ item.title }}
          </li>

        </ul>
      </div>
      <div class="list">
        <ul>
          <li v-for="(item, index) in newNewsList" :key="index" :class="{activeLi: item.id===activeLi}" @click="gotoArticleDetails(item)" @mouseleave="activeLi=''" @mouseover="activeLi=item.id">
            <div class="item">
              <div class="left">
                <img :src="imgPath">
              </div>
              <div class="right">
                <h3>{{ item.title }}</h3>
                <p>
                  {{ item.description }}
                </p>
              </div>
              <div class="date">
                <p class="month">{{ item.createTime | fileter_monteh }}</p>
                <p>{{ item.createTime | fileter_year }}</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="btnMore">
          <div class="btn" @click="addNewsList">
            {{ offBtn?'敬请期待更多内容...':'查看更多' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const imgPath = 'https://thcdn.gggamedownload.com/source/assets/neiye/sucai-12.png'
import { reqGetNewsList } from '@/api/news'
export default {
  name: 'List',
  filters: {
    fileter_monteh(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(index + 1)
        return newVal
      }
      return val
    },
    fileter_year(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(0, index)
        return newVal
      }
      return val
    }
  },
  data() {
    return {
      imgPath,
      offBtn: false,
      titleList: [
        {
          id: 1,
          title: '最新',
          type: null
        },
        {
          id: 2,
          title: '新闻',
          type: 'news'

        },
        {
          id: 3,
          title: '公告',
          type: 'announcement'

        },
        {
          id: 4,
          title: '活动',
          type: 'gameWalkthrough'

        },
        {
          id: 5,
          title: '见闻纪',
          type: 'gameStrategy'

        }
      ],
      newsList: [

      ],
      newNewsList: [],
      activeBorder: 1,
      activeLi: '',
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 5,
        currentPage: 1,
        tag: '',
        type: ''
      }
    }
  },
  created() {
    this.getList()
  },

  methods: {
    async getList() {
      if (this.offBtn) return
      const res = await reqGetNewsList(this.queryForm)
      if (res.data.length <= 0) {
        this.offBtn = true
      }
      this.newsList = [...this.newsList, ...res.data]
      this.newNewsList = this.newsList
    },
    async handleChangeType({ id, type }) {
      this.newsList = []
      this.offBtn = false
      this.activeBorder = id
      this.queryForm.tag = type
      this.queryForm.pageSize = 5
      this.queryForm.currentPage = 1
      const res = await reqGetNewsList(this.queryForm)
      if (res.data.length <= 0) {
        this.offBtn = true
      }
      this.newsList = res.data
      this.newNewsList = this.newsList
    },
    addNewsList() {
      // this.queryForm.pageSize += 5
      this.queryForm.currentPage += 1
      this.getList()
    },
    gotoArticleDetails({ id }) {
      this.$router.push({
        path: '/NewsDetails',
        query: {
          id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.body {
    .header {
      width: 100%;
      height: 105px;
      background-color: #fafafa;
      ul {
        width: 960px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        li {
          padding: 2px 0;
          width: 77px;
          text-align: center;
          color: #92a2ae;
          font-weight: 700;
          margin-right: 20px;
          cursor: pointer;
          &.active {
            border-radius: 17px;
            border: 1px solid #92a2ae;
          }
        }
      }
    }
    .list {
      width: 100%;
      ul {
        width: 100%;
        li {
          cursor: pointer;
          width: 100%;
          height: 152px;
          img {
            width: 181px;
          }
          &.activeLi {
            background-color: #f6f8fa;
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
            .item {
              border-top: none !important;
              border-bottom: none !important;
            }
            +li {
              .item {
              border-top: none !important;
              border-bottom: none !important;
            }
            }
          }
          &:last-child .item {
            border-bottom: 1px solid #ececec;
          }
        }
        .item {
          border-top: 1px solid #ececec;
          margin: 0 auto;
          width: 960px;
          display: flex;
          height: 152px;
          padding: 24px 0;

          .right {
            margin-left: 25px;
            width: 591px;
            h3 {
              padding-top: 12px;
              font-size: 17px;
            }
            p {
              padding-left: 6px;
              padding-top: 18px;
              color: #a7a7a7;
              font-size: 14px;
            }
          }
          .date {
            margin-left: auto;
            margin-top: 35px;
            text-align: right;
            color: #555555;
            .month {
              font-size: 26px;
            }
          }
        }
      }
    }

    .btnMore {
      margin-top: 32px;
      margin-bottom: 290px;
      .btn {
        cursor: pointer;
        width: 311px;
        height: 44px;
        background-color: #f3f3f3;
        text-align: center;
        line-height: 44px;
        margin: 0 auto;
      }
    }
  }
</style>
